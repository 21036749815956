import React from "react";
import Layout from "../components/layout";
import {Card, Row, Col, Image } from "react-bootstrap";
import CertImg from "../images/handshake.png"
import Seo from "../components/seo";

const Savings3 = () => (
    <Layout>
        <Seo title="Certificados de aportación" description="Describe certificados de aportación en CoopGestión"  />
        <Row>
            <Col>
                <div className="jumbotron bg-secondary text-white">
                <h1 className="display-4 jumbotron-title">CERTIFICADOS DE APORTACIÓN</h1>
                    <p className="lead">
                        Conviertete en nuestro socio!
                    </p>
                </div>
            
            </Col>
        </Row>
        <Row className="my-3">
            <Col sm className="m-auto">
                <Card>
                <Card.Header>Certificados de aportación</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Rentabilidad anual sobre excedentes al cierre del ejercicio 
                        económico.
                    </Card.Text>
                </Card.Body>
                </Card>
            </Col>
            <Col sm className="m-auto py-1 text-center">
                <Image src={CertImg} alt="Certificados de aportación" fluid/>
            </Col>
        </Row>


    </Layout>
);

export default Savings3;