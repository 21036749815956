import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import Header from "./header";
import "./layout.scss";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <>
      <Header/>
      <main>
        <Container className="p-1">{children}</Container>
      </main>
      <Footer noFooterLogos="true"></Footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
